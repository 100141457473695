import React from "react";
import { useTranslation } from "react-i18next";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Container from "../components/Container";

const NotFoundPage = () => {
  const { t } = useTranslation();
  return (
    <Layout>
      <SEO title="Error 404" />
      <div className="w-full bg-safiaBlue h-18"></div>
      <Container backgroundColor="#fff">
        <h1 className="text-h1mobile sm:text-h1desktop text-safiaBlue w-full lg:w-9/12">
          Error 404
        </h1>
        <p>{t("error404desc")}</p>
      </Container>
    </Layout>
  );
};

export default NotFoundPage;
